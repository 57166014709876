<template>
  <div v-title data-title="Online Games on H5joy ——Let's play" id="homeId">
    <StartAndEnd portal="mobile_home">
      <div>
        <AppList :appGameList="appGameList"></AppList>
      </div>
      <div class="shortcut-popup-desktop" v-if="$store.state.deferredPromptType && $store.state.installType == 1">
        <div class="shortcut-popup-desktop__description">
          <p class="shortcut-popup-desktop__text">Add a quick start icon for H5joy！</p>
        </div>
        <div class="shortcut-popup-desktop__buttons">
          <el-button @click="After">After</el-button>
          <el-button @click="addToDesktop">Add</el-button>
        </div>
      </div>
    </StartAndEnd>
  </div>
</template>

<script>
import StartAndEnd from "@/components/MobileTerminal/MobileHome/StartAndEnd";
import AppList from "@/components/MobileTerminal/MobileHome/AppList";

import {determinePcOrMove, getJson, pageInitLog, pageOutLog, clickGameLog, recentGame} from '@/utils/utils.js'
import gamepad from '@/assets/gamepad.png'
export default {
  name: "mobileIndex",
  data() {
    return {
      allGameList: [], // 全部游戏
      appGameList: [], // 中间游戏列表
      gamepad
    }
  },
  components: {
    StartAndEnd,
    AppList,
  },
  created() {
    if (localStorage.getItem('installCancelTime')) {
      if (this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).isAfter(this.$dayjs(localStorage.getItem('installCancelTime')))) {
        this.$store.commit("changeInstallType",'1')
        localStorage.setItem('installType','1')
        localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
      } else {
        this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
      }
    } else {
      this.$store.commit("changeInstallType",localStorage.getItem('installType') ? localStorage.getItem('installType') : '1')
    }
  },
  mounted() {
    if (determinePcOrMove() == 2) {
      let { channel } = this.$route.query
      this.$router.push({
        path: '/P/homeIndex',
        query: {
          channel
        }
      },()=>{})
    } else {

      // 进入页面埋点
      pageInitLog('mobile_home')

      let jsonArr = getJson() || [] // 原数组

      let allGameList = []
      jsonArr && jsonArr.map((item)=>{
        allGameList.push(item)
      })
      this.allGameList = allGameList

      let arr = jsonArr || [] // 原数组
      let newArr = [] // 新数组
      let num = Math.ceil(arr.length / 12)
      console.log(num);
      for ( let i = 1; i <= num; i++ ) {
        if (i == 1) {
          newArr[i - 1] = arr.splice(0,11)
        } else {
          newArr[i - 1] = arr.splice(0,12)
        }
      }
      console.log(newArr);
      this.appGameList = newArr
    }
  },
  methods: {
    addToDesktop() {
      console.log(this.$store.state.deferredPrompt);
      this.$store.state.deferredPrompt && this.$store.state.deferredPrompt.prompt();
      this.$store.commit("changePWA",{deferredPrompt: null,deferredPromptType: this.$store.state.deferredPromptType})
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    },
    After() {
      localStorage.setItem('installType','0')
      this.$store.commit("changeInstallType",'0')
      localStorage.setItem('installCancelTime',this.$dayjs().format('YYYY-MM-DD'))
    }
  },
  // beforeRouteLeave(to, from, next){
  //   console.log(to);
  //   console.log(from);
  //   if (to.query.gameId) {
  //     getJson().map((item)=>{
  //       if (item.gameId == to.query.gameId) {
  //         clickGameLog('mobile_home', item)
  //         recentGame(item)
  //       }
  //     })
  //   }
  //   next()
  // },
  beforeDestroy() {
    // 离开页面埋点
    pageOutLog('mobile_home')
  }
}
</script>

<style lang="less" scoped>
.top-text{
  text-align: center;
  padding: 10px 5px 5px;
  margin-top: 2.5555rem;
  h1{
    width: 100%;
    font-size: 14px;
    color: #fff;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
  }
}
#homeId{
  height: 100vh;
  overflow-y: auto;
  position: relative;
  .smegma{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    .btn{
      width: 10.3888rem;
      height: 3.5rem;
      margin: 42% auto 0;
      text-align: center;
      line-height: 3.3rem;
      font-size: 2rem;
      color: #874000;
    }
    .adv-bg{
      margin-top: 1rem;
    }
    .desc{
      padding: 0.5rem 0.8rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .shortcut-popup-desktop{
    background: #FFFFFF;
    border-radius: 18px;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 16px 62px rgba(3,21,48,.15);
    color: #222222;
    padding: 20px;
    position: fixed;
    right: 24px;
    z-index: 997;
    width: 80%;
    .shortcut-popup-desktop__description{
      .shortcut-popup-desktop__text{
        word-wrap: break-word;
        --light-cyan-100: #222222;
        box-sizing: border-box;
        color: var(--light-cyan-100);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        padding-right: 0;
      }
    }
    .shortcut-popup-desktop__buttons{
      margin-top: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      .el-button{
        width: 130px;
        background: #f0f0f0;
        border: none;
        color: #6f6f6f;
        font-size: 15px;
        font-weight: 600;
      }
      .el-button:nth-child(2){
        background: #198cff;
        color: #e9f2ff;
        margin-left: 20px;
      }
    }
  }
}
</style>
