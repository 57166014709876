<template>
  <div class="privacy_box">
    <br/>
    <a :href="(type == 2 ? '/P/homeIndex' : '/M/homeIndex')+($route.query.channel ? ('?channel='+$route.query.channel): '')">
      <h3 class="menber">h5joy.com </h3>
    </a>
    <br/>
    <div class="intro-text">
      This privacy policy covers how we treat personal information that we collect and receive. We do not intend to collect any personal information from children under 13 unless we believe such collection to be permitted by law. Please see below for our policy with respect to children under 13.<br/>
      <br/>
      <b>General</b>
      <br/>
      <br/>
      We gather user information to provide you with a customized experience on our site. Your user information helps us tailor the content, services, goods and advertising to your current and future needs. For this reason, we may ask you to register when you visit certain areas of our site. If you choose not to register you may not have access to areas that require personal identification.<br/>
      <br/>
      <b>1. THE PERSONAL INFORMATION THAT H5JOY COLLECTS AND RECEIVES</b>
      <br/>
      <br/>
      We may collect Personal Information (including but not limited to your name, email number and other contact information) for providing services and contact you. AS described in this Privacy Policy, through H5JOY Services, we may collects Personal Information for providing the Services and for other specific purposes. h5joy GAMES may change or update tools to collect information from time to time and may amend this privacy policy if the policy will be impacted by a new tool. When interacting with the Property, h5joy GAMES may collect/receive the following information, which includes Personal Information.<br/>
      <br/>
      <b>2. WHAT DOES H5JOY DO WITH PERSONAL INFORMATION?</b>
      <br/>
      <br/>
      We may provide, improve ourself and for other lawful purposes. H5JOY uses the Personal Information that it collects and receives to provide the Services; to provide support. h5joy GAMES uses Personal Information to enforce its terms, policies and legal agreements, to abide by court orders and warrants, to collect debts, and assist law enforcement agencies, prevent fraud, misappropriation, infringements, identity thefts and any other misuse of the Services, and to take any action in any legal dispute and proceeding. h5joy GAMES dedicates to process Personal Information only for the purposes specified in this policy.<br/>
      <br/>
      <b>3. INFORMATION SHARING</b>
      <br/>
      <br/>
      We may share Personal Information about you with Demand and Supply Partners, with data enrichment sources, with our affiliates, with law enforcement and on a merger or an acquisition. H5JOY will provide a prominent notice on the Site of any change in ownership or uses of this Personal Information if applicable. In any event, the entity which will assume the ownership of the Site will continue to protect Personal Information related to you under this Policy; Share it in any other circumstances where h5joy GAMES receives your written consent;<br/>
      <br/>
      <b>4. COOKIES WE USE COOKIES</b>
      <br/>
      <br/>
      We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. If you continue to use this site, you consent to our use of cookies. We and our advertisers may use cookies, or similar technologies to enhance and personalize your experience on the site, including the following: to operate and improve offerings on the site; to help authenticate you when you are on the site; to remember your preferences; to present and help measure and research the effectiveness of we offerings; to customize the content provided to you through the site; and, to show targeted ads. We also uses 3rd party vendor re-marketing tracking cookies, including the Google Adwords tracking cookie. This means we will continue to show ads to you across the internet, specifically on the Google Content Network (GCN). 5.YOUR CALIFORNIA PRIVACY RIGHTS If you are a California resident, California Civil Code Section 1798.83 permits you to request in writing a list of the categories of Personal Information relating third parties to which h5joy GAME has disclosed Personal Information during the preceding year for the third parties' direct marketing purposes. To make such a request, please contact us.<br/>
      <br/>
      <b>6.Binding Agreement</b>
      <br/>
      <br/>Your use of our site, including any dispute concerning privacy, is subject to this Privacy Policy and our Terms of Use. By using our Site, you are accepting the practices set out in this Privacy Policy and our Terms of Use. We reserve the right to modify this Privacy Policy at any time by posting the changes on this page. Please check the revision date at the bottom of this page to determine if the statement has been modified since you last reviewed it. Your continued use of any portion of our site following posting of the updated Privacy Policy will constitute your acceptance of the changes.
    </div>
    <br/>
    <div>PRIVACY POLICY</div>
    <br/>
    <p>Copyright h5joy.com. All rights reserved.</p>
  </div>
</template>

<script>
import { determinePcOrMove } from '@/utils/utils'
export default {
  name: "privacyIndex",
  components: {
  },
  data() {
    return {
      type: determinePcOrMove()
    }
  },
  mounted() {
    document.title = 'privacy'
  }
}
</script>

<style lang="less" scoped>
.privacy_box{
  width: 100%;
  height: 100vh;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}
</style>
